.info {
  border-top: 3px solid #1977cc;
  border-bottom: 3px solid #1977cc;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
}

.info-row {
display: flex;
align-items: center;
height: 80px;
margin: 2px 0px;
}

.info p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
}

.details {
    padding-left: 20px;
}

.icon-info {
  font-size: 40px;
  color: #1977cc;
  width: 50px;
}

.icon-info__address {
  font-size: 50px;
  color: #1977cc;
  margin-bottom: 6px;
  width: 50px;
}

.info-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #37517e;
}
