.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#treatments .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
background-color: #2c4964;
}

#treatments .nav-link {
  color: #124265
}
#treatments .nav-link.active {
  color: white
}


.section-header {
  position: relative;
  color: #124265;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.section-header::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #1977cc;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-header::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}


.ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #2487ce;
  line-height: 1;
}



.treatment-header {
  color: #2c4964;
}


.dts {
  font-size: 10px;
}

.header-icon {
  margin-right: 5px;
}
