.icon-box {
  text-align: center;
  border: 1px solid #d5e1ed;
  padding: 40px 20px;
  transition: all ease-in-out 0.3s;
}

.icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #1977cc;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #badaf7;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.icon-box .icon .icon-inner {
  color: #fff;
  font-size: 28px;
}

.icon-box:hover {
  background: #1977cc;
  border-color: #1977cc;
}

.icon-box:hover .icon {
  background: #fff;
}

.icon-box:hover h4,
.icon-box:hover p {
  color: #fff;
}

.icon-box:hover .icon {
  color: #1977cc;
}

.icon-box:hover .icon::before {
  background: #3291e6;
}

.icon-box:hover .icon .icon-inner {
  color: #1977cc;
}
